import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import intersect from '@alpinejs/intersect'

document.addEventListener('alpine:init', () => {
  Alpine.store('settings', {
    theme: Alpine.$persist('system').as('settings.theme'),
    init() {
      Alpine.effect(() => {
        window.updateTheme(this.theme)
      })
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', () => window.updateTheme(this.theme))
    },
  })
})

Alpine.plugin(persist)
Alpine.plugin(intersect)
Alpine.start()
